<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('germplasm.passport_data') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
                  <tbody>
                    <tr>
                      <th>{{ $t('germplasm.collection_date') }}</th>
                      <td>{{ formData.collection_date | dateFormat }}</td>
                      <th>{{ $t('germplasm.collection_source') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.collection_source : formData.collection_source_bn }}</td>
                    </tr>
                    <!-- <tr>
                      <th>{{ $t('germplasm.org_type') }}</th>
                      <td>{{formData.district_bn }}</td>
                      <th>{{ $t('germplasm.org_name') }}</th>
                      <td>{{ formData.fiscal_year }}</td>
                    </tr> -->
                    <tr>
                      <th>{{ $t('germplasm.collector_number') }}</th>
                      <td>{{ formData.collector_number }}</td>
                      <th>{{ $t('germplasm.accession_number') }}</th>
                      <td>{{ formData.accession_number }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germConfig.cropType') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.crop_type : formData.crop_type_bn }}</td>
                      <th>{{ $t('germConfig.cropName') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.crop_name : formData.crop_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germConfig.scientific_name') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.scientific_name : formData.scientific_name_bn }}</td>
                      <th>{{ $t('germConfig.english_name') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.english_name : formData.english_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germplasm.local_name') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.local_name : formData.local_name_bn }}</td>
                      <th>{{ $t('germplasm.cultivar_name') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.cultivar_name : formData.cultivar_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germplasm.area_type') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.area : formData.area_bn }}</td>
                      <th>{{ $t('globalTrans.division') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.division_name : formData.division_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{  $t('globalTrans.district') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.district_name : formData.district_name_bn }}</td>
                      <th>{{ $t('globalTrans.upazila') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.upazila_name : formData.upazila_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('globalTrans.union') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.union_name : formData.union_name_bn }}</td>
                      <th>{{ $t('globalTrans.address') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.address : formData.address_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germplasm.longitude') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.longitude : formData.longitude }}</td>
                      <th>{{ $t('germplasm.latitude') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.latitude : formData.latitude }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germplasm.collectors_name') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.collector_name : formData.collector_name_bn }}</td>
                      <th>{{ $t('germplasm.image') }}</th>
                      <td>{{ }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('germConfig.remarks') }}</th>
                      <td colspan="3">{{ currentLocale === 'en' ? formData.remarks : formData.remarks_bn }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
// import { lcList, lcInfo, programManagementShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getFormData()
      const test = this.getCustomDataList(tmp)
      this.formData = test
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        district_id: 0,
        region_id: 0,
        division_id: 0,
        allocation_date: '',
        fertilizer_info: [],
        godown_info: [],
        fiscal_year_id: 0,
        allocation_type_id: 0,
        other_district_id: 0,
        month: 0,
        sale_to: 0,
        memo_no: '',
        clause: '',
        godown: [],
        total: [],
        comments: '',
        comments_bn: ''
      },
            info: {
          fertilizer: []
      },
      godown_info: [],
      index: '',
      ministry: [],
      districtList: [],
      selectedGodown: []
    }
  },
  computed: {
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    areaTypeList: function () {
      const list = [
        { value: 1, text: 'City Corpoation', text_en: 'City Corpoation', text_bn: 'সিটি কর্পোরেশন' },
        { value: 2, text: 'Pauroshoba', text_en: 'Pauroshoba', text_bn: 'পৌরসভা' },
        { value: 3, text: 'Union', text_en: 'Union', text_bn: 'ইউনিয়ন' }
      ]
      return list
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    pdfExport () {
    const reportTitle = this.$t('germplasm.passport_data')
    ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this)
    },
    getCustomDataList (data) {
        const collectionObj = this.$store.state.SeedsFertilizer.commonObj.collectionSourceList.find(doc => doc.value === parseInt(data.collection_source_id))
        const collectionData = {}
        if (typeof collectionObj !== 'undefined') {
          collectionData.collection_source = collectionObj.text_en
          collectionData.collection_source_bn = collectionObj.text_bn
        } else {
          collectionData.collection_source = ''
          collectionData.collection_source_bn = ''
        }

        const areaObj = this.areaTypeList.find(doc => doc.value === parseInt(data.area_type_id))
        const areaData = {}
        if (typeof areaObj !== 'undefined') {
          areaData.area = areaObj.text_en
          areaData.area_bn = areaObj.text_bn
        } else {
          areaData.area = ''
          areaData.area_bn = ''
        }

        const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.gpCropNameList.find(doc => doc.value === parseInt(data.crop_name_id))
        const cropNameData = {}
        if (typeof cropNameObj !== 'undefined') {
          cropNameData.english_name = cropNameObj.text_en
          cropNameData.english_name_bn = cropNameObj.text_bn
          cropNameData.scientific_name = cropNameObj.scientific_name
          cropNameData.scientific_name_bn = cropNameObj.scientific_name_bn
        } else {
          cropNameData.english_name = ''
          cropNameData.english_name_bn = ''
          cropNameData.scientific_name = ''
          cropNameData.scientific_name_bn = ''
        }

        const divisionObject = this.$store.state.commonObj.divisionList.find(doc => doc.value === parseInt(data.division_id))
        const DivisionData = {}
        if (typeof divisionObject !== 'undefined') {
          DivisionData.division_name = divisionObject.text_en
          DivisionData.division_name_bn = divisionObject.text_bn
        } else {
          DivisionData.division_name = ''
          DivisionData.division_name_bn = ''
        }

        const districtObject = this.$store.state.commonObj.districtList.find(doc => doc.value === parseInt(data.district_id))
        const DistrictData = {}
        if (typeof districtObject !== 'undefined') {
          DistrictData.district_name = districtObject.text_en
          DistrictData.district_name_bn = districtObject.text_bn
        } else {
          DistrictData.district_name = ''
          DistrictData.district_name_bn = ''
        }

        const upazilaObject = this.$store.state.commonObj.upazilaList.find(doc => doc.value === parseInt(data.upazilla_id))
        const UpazilaData = {}
        if (typeof upazilaObject !== 'undefined') {
          UpazilaData.upazila_name = upazilaObject.text_en
          UpazilaData.upazila_name_bn = upazilaObject.text_bn
        } else {
          UpazilaData.upazila_name = ''
          UpazilaData.upazila_name_bn = ''
        }

        const unionObject = this.$store.state.commonObj.unionList.find(doc => doc.value === parseInt(data.union_id))
        const UnionData = {}
        if (typeof unionObject !== 'undefined') {
          UnionData.union_name = unionObject.text_en
          UnionData.union_name_bn = unionObject.text_bn
        } else {
          UnionData.union_name = ''
          UnionData.union_name_bn = ''
        }
        return Object.assign({}, data, collectionData, areaData, cropNameData, DivisionData, DistrictData, UpazilaData, UnionData)
    }
  }
}
</script>
