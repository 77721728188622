import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        // const len = fertilizer.length
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  { text: vm.$t('germplasm.collection_date'), style: 'td' },
                  { text: data.collection_date, style: 'td' },
                  { text: vm.$t('germplasm.collection_source'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.collection_source : data.collection_source_bn, style: 'td' }
                ],
                // [
                //   { text: vm.$t('germplasm.org_type'), style: 'td' },
                //   { text: vm.$i18n.locale === 'bn' ? data.district_bn : data.district, style: 'td' },
                //   { text: vm.$t('germplasm.org_name'), style: 'td' },
                //   { text: (i18n.locale === 'bn') ? data.fiscal_year : data.fiscal_year, style: 'td' }
                // ],
                [
                  { text: vm.$t('germplasm.collector_number'), style: 'td' },
                  { text: vm.$n(data.collector_number), style: 'td' },
                  { text: vm.$t('germplasm.accession_number'), style: 'td' },
                  { text: vm.$n(data.assession_number), style: 'td' }
                ],
                [
                  { text: vm.$t('germConfig.cropType'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.crop_type : data.crop_type_bn, style: 'td' },
                  { text: vm.$t('germConfig.cropName'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.crop_name : data.crop_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('germConfig.scientific_name'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.scientific_name : data.scientific_name_bn, style: 'td' },
                  { text: vm.$t('germConfig.english_name'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.english_name : data.english_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('germplasm.local_name'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.local_name : data.local_name_bn, style: 'td' },
                  { text: vm.$t('germplasm.cultivar_name'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.cultivar_name : data.cultivar_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('germplasm.area_type'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.area : data.area_bn, style: 'td' },
                  { text: vm.$t('globalTrans.division'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.division_name : data.division_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('globalTrans.district'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.district_name : data.district_name_bn, style: 'td' },
                  { text: vm.$t('globalTrans.upazila'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.upazila_name : data.upazila_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('globalTrans.union'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.union_name : data.union_name_bn, style: 'td' },
                  { text: vm.$t('globalTrans.address'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.address : data.address_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('germplasm.longitude'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.longitude : data.longitude, style: 'td' },
                  { text: vm.$t('germplasm.latitude'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.latitude : data.latitude, style: 'td' }
                ],
                [
                  { text: vm.$t('germplasm.collectors_name'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.collector_name : data.collector_name_bn, style: 'td' },
                  { text: vm.$t('germplasm.image'), style: 'td' },
                  { text: '', style: 'td' }
                ],
                [
                  { text: vm.$t('germConfig.remarks'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.remarks : data.remarks_bn, style: 'td', colSpan: '3' },
                  { },
                  { }
                ]
              ]
            }
          }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
            th: {
              bold: true,
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            fertilizer: {
              fontSize: 10,
              margin: [10, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 0]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
